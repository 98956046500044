<template>
  <div>
    <b-modal
      @hidden="toggleAccountSelectorModal"
      @ok="toggleAccountSelectorModal"
      v-model="internalModalVisible"
      ok-only
      no-close-on-backdrop
      ok-title="CLOSE"
      centered
      size="lg"
      title="Select Account"
    >
      <b-card-text>
        <app-collapse type="border" class="mainContainer">
          <app-collapse-item
            v-for="(account, accountIndex) in accounts"
            :key="account.id"
            :title="account.name"
          >
            <app-collapse type="border" class="mb-1">
              <b-card
              class="innerContainer"
                v-for="(property, propertyIndex) in account.ga4Properties"
                :key="property.id"
              >
                <div style="display: flex; justify-content:space-between">
                  <div class="pr-1 pt-0 edit-form">
                    <h3 class="app-title sm">
                       {{ property.displayName }}
                    </h3>
                    <span>{{ property.id }}</span>
                </div>
                <div>
                  <b-button
                    @click="
                      selectGoogleViewAccount(property.id,account.name,property.displayName,property.account,{
                        accountIndex,
                        propertyIndex
                      })
                    "
                    variant="primary"
                    >
                    <b-spinner v-if="property.loading" small />
                     Select Account</b-button
                  >
                </div>
                </div>
              </b-card>
            </app-collapse>
          </app-collapse-item>
        </app-collapse>
      </b-card-text>
    </b-modal>
  </div>
</template>

<script>
import { BModal, BCardText, BCard, BButton, BSpinner } from "bootstrap-vue";
import AppCollapse from "@core/components/app-collapse/AppCollapse.vue";
import AppCollapseItem from "@core/components/app-collapse/AppCollapseItem.vue";
export default {
  props: {
    accountSelectorModal: {
      type: Boolean,
      default: false,
    },
    accounts: {
      type: Array,
      default: [],
    },
  },
  data() {
    return {
      internalModalVisible : this.accountSelectorModal
    };
  },
  watch: {
    accountSelectorModal(value) {
      this.internalModalVisible = value;
    },
  },
  components: {
    BModal,
    AppCollapse,
    AppCollapseItem,
    BCardText,
    BCard,
    BButton,
    BSpinner,
  },
  methods: {
    toggleAccountSelectorModal() {
      this.$emit("toggleAccountSelectorModal", false);
    },
    selectGoogleViewAccount(viewID, profileName,propertyName,ProperyID, buttonData) {
      this.$emit("selectGoogleViewAccount", {
        viewID,
        profileName,
        propertyName,
        ProperyID,
        buttonData,
      });
    },
  },
};
</script>

<style scoped>
.mainContainer{
  width: 760px;
}
.innerContainer{
  width: 730px;
}
@media (max-width: 587px) {
  .mainContainer{
    width: 300px !important;
  }
  .innerContainer{
    width: 270px !important;
}
}
@media (max-width: 890px) {
  .mainContainer{
    width: 465px;
  }
  .innerContainer{
    width: 438px;
}
  
}
</style>