var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-modal',{attrs:{"hide-footer":"","no-close-on-backdrop":"","centered":"","size":"lg","title":"Select dv360 Advertisers"},on:{"hidden":_vm.toggle360Selections,"ok":_vm.toggle360Selections},model:{value:(_vm.internalModalVisible),callback:function ($$v) {_vm.internalModalVisible=$$v},expression:"internalModalVisible"}},[(this.isFetchingDv360Advertiser)?_c('b-row',{staticClass:"spinnerCentered"},[_c('div',[_c('multiChannelSpinner')],1)]):(this.dv360Accounts.length === 0)?_c('b-row',{staticClass:"center-text-no-found"},[_c('div',[_c('p',[_vm._v("No data found!")])])]):_c('b-row',[[_c('b-col',{attrs:{"cols":"12"}},[_c('b-card-text',[_c('app-collapse',{staticClass:"mainContainer",attrs:{"type":"border"}},_vm._l((_vm.dv360Accounts),function(customer,customerIndex){return _c('app-collapse-item',{key:customer.partnerId,attrs:{"title":("" + (customer.displayName))}},[(
                    customer.advertiserAccounts &&
                      customer.advertiserAccounts.length > 0
                  )?_c('div',[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.searchAdvertiser),expression:"searchAdvertiser"}],staticClass:"search-option",attrs:{"placeholder":"search customer.."},domProps:{"value":(_vm.searchAdvertiser)},on:{"input":function($event){if($event.target.composing){ return; }_vm.searchAdvertiser=$event.target.value}}}),_c('b-list-group',_vm._l((_vm.paginatedAdvertisers(customer)),function(advertiser,advertiserIndex){return _c('b-list-group-item',{key:advertiser.advertiserId},[_c('div',{staticClass:"d-flex align-items-center justify-content-between ml-1 d-inline",staticStyle:{"margin-top":"8px"}},[_c('div',[_c('h5',[_vm._v(_vm._s(advertiser.displayName))]),_c('h6',[_vm._v(_vm._s(advertiser.advertiserId))])]),_c('div',{staticClass:"radio-container"},[_c('b-form-checkbox',{attrs:{"checked":_vm.isSelected(advertiser.advertiserId),"id":String(advertiserIndex)},on:{"change":function($event){return _vm.toggleDv360Selection(
                                advertiser,
                                advertiserIndex,
                                advertiser.advertiserId,
                                advertiser.displayName,
                                advertiser.partnerId
                              )}}})],1)])])}),1),_c('div',{staticClass:"pagination mt-2",staticStyle:{"display":"flex","justify-content":"center","align-items":"center","margin-top":"5px"}},[_c('b-pagination',{attrs:{"total-rows":_vm.pageLength,"per-page":_vm.itemsPerPage,"align":"center"},model:{value:(customer.currentPage),callback:function ($$v) {_vm.$set(customer, "currentPage", $$v)},expression:"customer.currentPage"}})],1)],1):_c('div',[_c('p',[_vm._v("No advertiser accounts available.")])])])}),1)],1)],1)]],2),(this.advertiserAccounts.length > 0)?_c('b-row',[_c('b-col',{attrs:{"cols":"12"}},[_c('div',{staticStyle:{"display":"flex","justify-content":"space-between","gap":"10px","margin-top":"5px","padding":"0px 8px !important"}},[_c('b-button',{staticClass:"mr-1",attrs:{"variant":"outline-primary"},on:{"click":_vm.closeModal}},[_vm._v("Cancel")]),_c('b-button',{attrs:{"variant":"primary"},on:{"click":_vm.saveDv360Data}},[_vm._v("Submit")])],1)])],1):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }